// import gsap from "gsap";
import { gsap, ScrollTrigger } from "gsap/all";

import { SplitText } from "gsap/SplitText";
// import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollSmoother } from "gsap/ScrollSmoother";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { Flip } from "gsap/Flip";

let mm = gsap.matchMedia();
gsap.registerPlugin(
  ScrollTrigger,
  SplitText,
  ScrollSmoother,
  ScrollToPlugin,
  Flip
);

// three boxes grid
const gridThreeStates = [
  // 1 active
  [`1 / 1 / 9 / 7`, `1 / 7 / 5 / 13`, `5 / 7 / 9 / 13`],
  // 2 active
  [`1 / 1 / 9 / 5`, `1 / 5 / 7 / 13`, `7 / 5 / 9 / 13`],
  // 3 active
  [`1 / 1 / 9 / 5`, `1 / 5 / 3 / 13`, `3 / 5 / 9 / 13`],
];

// six boxes grid
const gridSixStates = [
  //1 active
  [
    `1 / 1 / 9 / 7`,
    `1 / 7 / 5 / 13`,
    `5 / 7 / 9 / 10`,
    `5 / 10 / 9 / 13`,
    `9 / 1 / 13 / 7`,
    `9 / 7 / 13 / 13`,
  ],

  // 2 active
  [
    `1 / 1 / 9 / 5`,
    `1 / 5 / 6 / 13`,
    `6 / 5 / 9 / 9`,
    `6 / 9 / 9 / 13`,
    `9 / 1 / 13 / 7`,
    `9 / 7 / 13 / 13`,
  ],

  // 3 active
  [
    `1 / 1 / 10 / 4`,
    `1 / 4 / 3 / 13`,
    `3 / 4 / 10 / 10`,
    `3 / 10 / 10 / 13`,
    `10 / 1 / 13 / 7`,
    `10 / 7 / 13 / 13`,
  ],

  // 4 active
  [
    `1 / 1 / 10 / 4`,
    `1 / 4 / 3 / 13`,
    `3 / 4 / 10 / 7`,
    `3 / 7 / 10 / 13`,
    `10 / 1 / 13 / 7`,
    `10 / 7 / 13 / 13`,
  ],

  // 5 active
  [
    `1 / 1 / 6 / 7`,
    `1 / 7 / 5 / 13`,
    `5 / 7 / 9 / 10`,
    `5 / 10 / 9 / 13`,
    `6 / 1 / 13 / 7`,
    `9 / 7 / 13 / 13`,
  ],

  // 6 active
  [
    `1 / 1 / 9 / 4`,
    `1 / 4 / 4 / 13`,
    `4 / 4 / 7 / 8`,
    `4 / 8 / 7 / 13`,
    `9 / 1 / 13 / 4`,
    `7 / 4 / 13 / 13`,
  ],
];

mm.add("(min-width: 1024px)", () => {
  /**  Hover Grid */
  const hoverGrids = gsap.utils.toArray(".grid.hover-grid");
  hoverGrids.forEach((hoverGrid, i) => {
    console.log("hoverGrid", hoverGrid);
    const hgItems = hoverGrid.querySelectorAll(".box");
    const hgItemCutouts = gsap.utils.toArray(".grid.hover-grid .box svg");
    const hgItemLinks = gsap.utils.toArray(".grid.hover-grid .box a");
    const hgItemArrows = gsap.utils.toArray(
      ".grid.hover-grid .box a svg g.arrow"
    );

    //primary loop over boxes
    hgItems.forEach((item, j) => {
      item.addEventListener("mouseenter", function (e) {
        //console.log("mouseenter a box");
        setGridByLayoutArray(hoverGrid, hgItems, item, j, hgItemCutouts);
        gsap.to([e.currentTarget, e.currentTarget.querySelector('.content-wrapper')], {
          borderBottomRightRadius: 105,
          duration: 0.6,
        });
      });

      item.addEventListener("mouseleave", function (e) {
        gsap.to([e.currentTarget, e.currentTarget.querySelector('.content-wrapper')], {
          borderBottomRightRadius: 40,
          duration: 0.6,
        });
      });
    });

    // arrow movement
    hgItemLinks.forEach((link, i) => {
      link.addEventListener("mouseenter", function () {
        //console.log("mouseenter a link");
        gsap.to(hgItemArrows[i], {
          rotate: -45,
          ease: "bounce.inOut",
          duration: 0.125,
        });
      });
      link.addEventListener("mouseleave", function () {
        // console.log("mouseleave");
        gsap.to(hgItemArrows[i], {
          rotate: 0,
          ease: "bounce.inOut",
          duration: 0.125,
        });
      });
    });

    // image effect
    hoverGrid.addEventListener("mousemove", function (event) {
      const { left, top, width, height } = hoverGrid.getBoundingClientRect();
      const centerX = left + width / 2;
      const centerY = top + height / 2;
      const dx = event.clientX - centerX;
      const dy = event.clientY - centerY;
      const angle = Math.atan2(dy, dx);
      const distance = Math.sqrt(dx * dx + dy * dy);
      const maxDistance = Math.sqrt(
        (width / 2) * (width / 2) + (height / 2) * (height / 2)
      );

      const translateX = Math.cos(angle) * distance;
      const translateY = Math.sin(angle) * distance;

      const maxTranslate = maxDistance / 2;

      const img = document.querySelector(".box.active .img-wrapper img");
      const imgGsap = gsap.utils.selector(".box.active .img-wrapper img");
      if (img) {
        const multiplier = -0.025;
        img.style.transform = `translate(${translateX * multiplier}px, ${
          translateY * multiplier
        }px)`;
      }
    });
  });

  function setGridByLayoutArray(grid, items, item, idx, hgItemCutouts) {
    if (window.innerWidth < 1024) {
      return;
    }
    if (items.length != 6 && items.length != 3) {
      return;
    }
    let newLayout; // array of positions
    if (items.length == 6) {
      newLayout = gridSixStates[idx];
    }
    if (items.length == 3) {
      newLayout = gridThreeStates[idx];
    }

    // console.log("newLayout is", newLayout);
    const state = Flip.getState([item, items]);

    const myImg = item.querySelector(".img-wrapper");

    items.forEach((x, j) => {
      // console.log("j:", newLayout[j]);
      x.style.gridArea = newLayout[j];
      x.querySelector(".img-wrapper").classList.add("hidden");
      if (j == idx) {
        x.classList.add("active");
        gsap.to(hgItemCutouts[idx], {
          x: 2,
          y: 2,
          duration: 0.5,
        });
      } else {
        x.classList.remove("active");
        gsap.to(hgItemCutouts[j], {
          x: 150,
          y: 150,
        });
      }
    });

    setTimeout(() => {
      myImg.classList.remove("hidden");
    }, 0);

    Flip.from(state, { duration: 0.75, absolute: true });

    // console.log(`Layout #${idx + 1}`);

    // console.log(grid, items, item, idx);
  }

  /** / Hover Grid */
});


function horizontalLoop(items, config) {
	items = gsap.utils.toArray(items);
	config = config || {};
	let tl = gsap.timeline({repeat: config.repeat, paused: config.paused, defaults: {ease: "none"}, onReverseComplete: () => tl.totalTime(tl.rawTime() + tl.duration() * 100)}),
		length = items.length,
		startX = items[0].offsetLeft,
		times = [],
		widths = [],
		xPercents = [],
		curIndex = 0,
		pixelsPerSecond = (config.speed || 1) * 100,
		snap = config.snap === false ? v => v : gsap.utils.snap(config.snap || 1), // some browsers shift by a pixel to accommodate flex layouts, so for example if width is 20% the first element's width might be 242px, and the next 243px, alternating back and forth. So we snap to 5 percentage points to make things look more natural
		totalWidth, curX, distanceToStart, distanceToLoop, item, i;
	gsap.set(items, { // convert "x" to "xPercent" to make things responsive, and populate the widths/xPercents Arrays to make lookups faster.
		xPercent: (i, el) => {
			let w = widths[i] = parseFloat(gsap.getProperty(el, "width", "px"));
			xPercents[i] = snap(parseFloat(gsap.getProperty(el, "x", "px")) / w * 100 + gsap.getProperty(el, "xPercent"));
			return xPercents[i];
		}
	});
	gsap.set(items, {x: 0});
	totalWidth = items[length-1].offsetLeft + xPercents[length-1] / 100 * widths[length-1] - startX + items[length-1].offsetWidth * gsap.getProperty(items[length-1], "scaleX") + (parseFloat(config.paddingRight) || 0);
	for (i = 0; i < length; i++) {
		item = items[i];
		curX = xPercents[i] / 100 * widths[i];
		distanceToStart = item.offsetLeft + curX - startX;
		distanceToLoop = distanceToStart + widths[i] * gsap.getProperty(item, "scaleX");
		tl.to(item, {xPercent: snap((curX - distanceToLoop) / widths[i] * 100), duration: distanceToLoop / pixelsPerSecond}, 0)
		  .fromTo(item, {xPercent: snap((curX - distanceToLoop + totalWidth) / widths[i] * 100)}, {xPercent: xPercents[i], duration: (curX - distanceToLoop + totalWidth - curX) / pixelsPerSecond, immediateRender: false}, distanceToLoop / pixelsPerSecond)
		  .add("label" + i, distanceToStart / pixelsPerSecond);
		times[i] = distanceToStart / pixelsPerSecond;
	}
	function toIndex(index, vars) {
		vars = vars || {};
		(Math.abs(index - curIndex) > length / 2) && (index += index > curIndex ? -length : length); // always go in the shortest direction
		let newIndex = gsap.utils.wrap(0, length, index),
			time = times[newIndex];
		if (time > tl.time() !== index > curIndex) { // if we're wrapping the timeline's playhead, make the proper adjustments
			vars.modifiers = {time: gsap.utils.wrap(0, tl.duration())};
			time += tl.duration() * (index > curIndex ? 1 : -1);
		}
		curIndex = newIndex;
		vars.overwrite = true;
		return tl.tweenTo(time, vars);
	}
	tl.next = vars => toIndex(curIndex+1, vars);
	tl.previous = vars => toIndex(curIndex-1, vars);
	tl.current = () => curIndex;
	tl.toIndex = (index, vars) => toIndex(index, vars);
	tl.times = times;
  tl.progress(1, true).progress(0, true); // pre-render for performance
  if (config.reversed) {
    tl.vars.onReverseComplete();
    tl.reverse();
  }
	return tl;
}
